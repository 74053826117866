import React, {lazy} from "react";

// Styles
import '../Styles/components/loader.scss';

// Assets
import loader from '../Assets/img/loader.svg';
import loaderButton from '../Assets/img/loader-white.svg';

// Components
const Img = lazy(() => import('./img'));

export const Loader = () => {
    return (
        <div className="loader">
            <Img src={loader} alt="" width="60" height="40"/>
        </div>
    )
};

export const LoaderButton = () => {
    return (
        <div className="loader button--">
            <Img src={loaderButton} alt="" width="60" height="40"/>
            &nbsp;
        </div>
    )
};

export const LoaderPage = () => {
    return <div className="loading-page">
        <div className="loading_headImg"/>
        <div className="layout">
            <div className="container">
                <div className="loading_inner">
                    <div className="loading">
                        <div className="blank blank_title"/>
                        <div className="shape shape_title"/>
                        <div className="blank blank_intro"/>
                        <div className="shape shape_intro"/>
                    </div>
                </div>
                <div className="loading">
                    <div className="blank blank_fat"/>
                    <div className="shape shape_fat"/>
                    <div className="blank blank_text"/>
                    <div className="shape shape_text"/>
                </div>
            </div>
        </div>
    </div>
};

export const LoaderResults = () => {
    return <div className="loading-page">
        <div className="layout">
            <div className="container">
                <div className="loading">
                    <div className="shape shape_title"/>
                    <div className="blank blank_title"/>
                    <div className="shape shape_title"/>
                    <div className="blank blank_title"/>
                    <div className="shape shape_title"/>
                    <div className="blank blank_title"/>
                    <div className="shape shape_title"/>
                    <div className="blank blank_title"/>
                    <div className="shape shape_title"/>
                    <div className="blank blank_title"/>
                    <div className="shape shape_title"/>
                    <div className="blank blank_title"/>
                    <div className="shape shape_title"/>
                    <div className="shape shape_title"/>
                </div>
            </div>
        </div>
    </div>
};