import {saveState, loadState} from '../../Utils/loadState';
import {
    GLOBAL_UPDATE_GLOBAL,
    GLOBAL_UPDATE_ROUTES,
    GLOBAL_IS_SCROLLLOCKED,
    GLOBAL_UPDATE_LANG,
    GLOBAL_UPDATE_GTM,
    GLOBAL_UPDATE_YOUTUBE,
    GLOBAL_UPDATE_VIMEO,
    GLOBAL_UPDATE_NOTIFICATION_HOME,
    GLOBAL_UPDATE_NOTIFICATION_ACCOUNT_INFOS,
    GLOBAL_GIVEAWAY_POPIN_OPENED
} from "../action";

let initialState = {
    global: {},
    routes: [
        {path: '/', exact: true, _uid: 'page-2-2', component: 'Template Home'}
    ],
    lockScroll: false,
    lang: loadState('site-lang') || 'fr',
    gtm: false,
    youtube: false,
    vimeo: false,
    notificationHome: null,
    notificationAccountInfos: null,
    giveAwayPopin: false
};

/**
 *
 * @param {*} state
 * @param {*} action
 */

export function globalReducer(state = initialState, action) {
    switch (action.type) {
        case GLOBAL_UPDATE_GLOBAL:
            return {...state, global: action.value};
        case GLOBAL_UPDATE_ROUTES:
            return {...state, routes: action.value};
        case GLOBAL_UPDATE_LANG:
            saveState('site-lang', action.value);
            document.querySelector('html').setAttribute('lang', action.value);
            return {...state, lang: action.value};
        case GLOBAL_IS_SCROLLLOCKED:
            if (action.value === true) {
                document.documentElement.classList.add('scrollLocked');
                document.body.classList.add('scrollLocked');
                document.body.style.height = window.innerHeight + 'px';
            } else {
                document.documentElement.classList.remove('scrollLocked');
                document.body.classList.remove('scrollLocked');
                document.body.style.height = null;
            }
            return {...state, lockScroll: action.value};
        case GLOBAL_UPDATE_GTM:
            return {...state, gtag: action.value};
        case GLOBAL_UPDATE_YOUTUBE:
            return {...state, youtube: action.value};
        case GLOBAL_UPDATE_VIMEO:
            return {...state, vimeo: action.value};
        case GLOBAL_UPDATE_NOTIFICATION_HOME:
            return {...state, notificationHome: action.value};
        case GLOBAL_UPDATE_NOTIFICATION_ACCOUNT_INFOS:
            return {...state, notificationAccountInfos: action.value};
        case GLOBAL_GIVEAWAY_POPIN_OPENED:
            return {...state, giveAwayPopin: action.value};
        default:
            return state;
    }
}
