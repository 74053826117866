import {saveState, loadState} from '../../Utils/loadState';
import {
    USER_UPDATE, USER_VIEW_AD, USER_VIEW_NL
} from "../action";


let initialState = {
    user: {
        name: loadState('name') || null,
        id: loadState('userID') || null,
        favorites: loadState('favorites') || null,
        favoritesData: null,
        addFav: false
    },
    view_ad: loadState('view_ad') || false,
    view_nl: loadState('view_nl') || false,
};

/**
 *
 * @param {*} state
 * @param {*} action
 */
export function accountReducer(state = initialState, action) {
    switch (action.type) {
        case USER_UPDATE:
            saveState('name', action.value.name);
            saveState('userID', action.value.id);
            saveState('favorites', action.value.favorites);
            return {...state, user: action.value};
        case USER_VIEW_AD:
            saveState('view_ad', action.value);
            return {...state, view_ad: action.value};
        case USER_VIEW_NL:
            saveState('view_nl', action.value);
            return {...state, view_nl: action.value};
        default:
            return state;
    }
}
