import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "scroll-behavior-polyfill";
import React, {lazy, StrictMode, Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import * as serviceWorker from './serviceWorker';

// Redux
import {createStore, combineReducers} from 'redux';
import {Provider} from 'react-redux';
// import App from './App';
import {globalReducer} from './Store/Reducers/globalReducer';
import {pageReducer} from './Store/Reducers/pageReducer';
import {breakpointReducer} from './Store/Reducers/breakpointReducer';
import {modalsReducer} from './Store/Reducers/modalsReducer';
import {accountReducer} from "./Store/Reducers/accountReducer";
import {routesReducer} from "./Store/Reducers/routesReducer";
import {LoaderPage} from "./Components/loader";

const reducer = combineReducers({
    global: globalReducer,
    page: pageReducer,
    breakpoint: breakpointReducer,
    modals: modalsReducer,
    account: accountReducer,
    routes: routesReducer
});


// const LoaderPage = lazy(
//     () => import('./Components/loader').then(module => ({default: module.LoaderPage}))
// );
const store = createStore(reducer);

const container = document.getElementById('root');
const root = createRoot(container);

const App = lazy(() => import('./App'));

root.render(
    <StrictMode>
        <Provider store={store}>
            <Suspense fallback={<LoaderPage/>}>
                <App/>
            </Suspense>
        </Provider>
    </StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
