import { MODALS_OPEN, BUBBLES_OPEN } from "../action";

const initialState = {
    openModal: {
        'login': false,
        'robot': false,
        'ad': false,
        'nl': false,
        'formNl': false,
        'confirmNl': false,
        'generique': false,
        'firstFavorite': false,
        'saveSearch': false,
        'submitAdoption': false,
        'submitForm': false,
        'info': false,
    },
    openBubble: {
        'notification': false
    }
};

/**
 * MODALS REDUCER
 * @param {*} state
 * @param {*} action
 */
export function modalsReducer(state = initialState, action) {
    switch (action.type) {
        case MODALS_OPEN:
            return { ...state, openModal: action.value };
        case BUBBLES_OPEN:
            return { ...state, openBubble: action.value };
        default:
            return state
    }
}
