// GLOBAL STATE
export function updateGlobalStoreGlobal(datas) {
    return {type: GLOBAL_UPDATE_GLOBAL, value: datas}
}

export function updateGlobalStoreRoutes(datas) {
    return {type: GLOBAL_UPDATE_ROUTES, value: datas}
}

export function updateGlobalStoreLang(datas) {
    return {type: GLOBAL_UPDATE_LANG, value: datas}
}

// RGPD
export function updateGTM(bool) {
    return {type: GLOBAL_UPDATE_GTM, value: bool}
}

export function updateYoutube(bool) {
    return {type: GLOBAL_UPDATE_YOUTUBE, value: bool}
}

export function updateVimeo(bool) {
    return {type: GLOBAL_UPDATE_VIMEO, value: bool}
}

// NOTIFICATION
export function updateNotificationHome(string) {
    return {type: GLOBAL_UPDATE_NOTIFICATION_HOME, value: string}
}

export function updateNotificationAccountInfos(string) {
    return {type: GLOBAL_UPDATE_NOTIFICATION_ACCOUNT_INFOS, value: string}
}

// SCROLL STATE
export function updateGlobalScrollLock(bool) {
    return {type: GLOBAL_IS_SCROLLLOCKED, value: bool}
}

// PAGE
export function updatePage(string) {
    return {type: UPDATE_PAGE, value: string}
}


// MODAL
export function updateModalsOpen(datas) {
    return {type: MODALS_OPEN, value: datas}
}


// BREAKPOINTS
export function updateIsMobile(bool) {
    return {type: IS_MOBILE, value: bool}
}

export function updateIsDesktop(bool) {
    return {type: IS_DESKTOP, value: bool}
}

export function updateIsDesktop1200(bool) {
    return {type: IS_DESKTOP1200, value: bool}
}


// USER
export function updateUserUpdate(datas) {
    return {type: USER_UPDATE, value: datas}
}

export function updateUserViewAd(bool) {
    return {type: USER_VIEW_AD, value: bool}
}

export function updateUserViewNl(bool) {
    return {type: USER_VIEW_NL, value: bool}
}

// BUBBLES
export function updateBubblesOpen(datas) {
    return {type: BUBBLES_OPEN, value: datas}
}


// Routes
export function updateRoutesUpdate(datas) {
    return {type: ROUTES_UPDATE, value: datas}
}


export function updateGiveAwayPopinOpened(bool) {
    return {type: GLOBAL_GIVEAWAY_POPIN_OPENED, value: bool}
}

export const GLOBAL_UPDATE_GLOBAL = 'UPDATE_GLOBAL';
export const GLOBAL_UPDATE_ROUTES = 'UPDATE_ROUTES';
export const GLOBAL_UPDATE_LANG = 'UPDATE_LANG';
export const GLOBAL_IS_SCROLLLOCKED = 'IS_SCROLLLOCKED';
export const GLOBAL_UPDATE_GTM = 'GLOBAL_UPDATE_GTM';
export const GLOBAL_UPDATE_YOUTUBE = 'GLOBAL_UPDATE_YOUTUBE';
export const GLOBAL_UPDATE_VIMEO = 'GLOBAL_UPDATE_VIMEO';
export const GLOBAL_UPDATE_NOTIFICATION_HOME = 'GLOBAL_UPDATE_NOTIFICATION_HOME';
export const GLOBAL_UPDATE_NOTIFICATION_ACCOUNT_INFOS = 'GLOBAL_UPDATE_NOTIFICATION_ACCOUNT_INFOS';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const MODALS_OPEN = 'MODALS_OPEN';
export const IS_MOBILE = 'IS_MOBILE';
export const IS_DESKTOP = 'IS_DESKTOP';
export const IS_DESKTOP1200 = 'IS_DESKTOP1200';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_VIEW_AD = 'USER_VIEW_AD';
export const USER_VIEW_NL = 'USER_VIEW_NL';
export const BUBBLES_OPEN = 'BUBBLES_OPEN';
export const ROUTES_UPDATE = 'ROUTES_UPDATE';
export const GLOBAL_GIVEAWAY_POPIN_OPENED = 'GLOBAL_GIVEAWAY_POPIN_OPENED';
